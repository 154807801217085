/* Font - Aeonik */

@font-face {
  font-family: 'AeonikPro';
  font-style: normal;
  font-weight: normal;
  src: local('AeonikPro-Light'),
    url('../fonts/AeonikPro-Light.otf') format('opentype');
}

@font-face {
  font-family: 'AeonikPro';
  font-style: normal;
  font-weight: 500;
  src: local('AeonikPro-Medium'),
    url('../fonts/AeonikPro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'AeonikPro';
  font-style: normal;
  font-weight: 600;
  src: local('AeonikPro-Medium'),
    url('../fonts/AeonikPro-Medium.otf') format('opentype');
}
